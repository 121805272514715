import {bindable, computedFrom, customElement, inject} from 'aurelia-framework';
import {Client} from '../../api/client';
import {DialogService} from "aurelia-dialog";
import {SeatAssignmentChoiceForm} from './seat-assignment-choice-form';
import {BindingSignaler} from "aurelia-templating-resources";

@customElement('sio-seat-assignment-choice-input')
@inject(
    Client,
    DialogService,
    BindingSignaler
)
export class SeatAssignmentChoiceElement {
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    loading = true;
    _choices = [];

    constructor(
        client,
        dialogService,
        signaler
    ) {
        this.client = client;
        this.dialogService = dialogService;
        this.signaler = signaler;
    }

    async attached() {
        this.loading = true;
        this._choices = this.config?.choices ?? [];
        this.loading = false;
    }

    @computedFrom('config')
    get _widthCalc() {
        return 'width: calc(100% - 50px)';
    }

    changeChoice(choice = null) {
        this.dialogService.open({
            viewModel: SeatAssignmentChoiceForm,
            model: {
                value: this.value,
                config: this.config,
                contextId: this.config?.options?.contextId ?? '',
                modelId: this.config?.options?.modelId ?? '',
            },
            lock: false
        }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
                this.value = response.output?.seatIdentifier;
            }
        });
    }
}

