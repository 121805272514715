import {bindable, inject, LogManager, customElement} from 'aurelia-framework';
import {ChoiceLoader} from "../loader/choice-loader.js";
import {I18N} from "aurelia-i18n";
import * as _ from "lodash";

import "./checkboxes-table-choice-element.less";

const logger = LogManager.getLogger('CheckboxesTableChoiceElement');

@customElement('sio-checkboxes-table-choice-input')
@inject(
    ChoiceLoader,
    I18N
)
export class CheckboxesTableChoiceElement
{
    @bindable searchText = "";
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;
    @bindable sort = {};
    @bindable filter = {
        untilToday: false,
        text: false,
    };

    constructor(
        choiceLoader,
        i18n
    ) {
        this.choiceLoader = choiceLoader;
        this.i18n = i18n;
        this.choicesInitialSortOrder = [];
        this.choicesInitialSortOrderBackup = [];
    }

    async configChanged(newValue, oldValue)
    {
        this.loading = true;

        if (this.config.translateChoiceLabel == null) {
            this.config.translateChoiceLabel = true;
        }

        this._prepareColumns();

        this.choices = await this.choiceLoader.getChoices(this.config).then(choices => {
            const result = [];

            choices.forEach((element) => {
                result.push({
                    label: this.config.translateChoiceLabel ?
                        this._translateLabel(element.label) : this._labelToArray(element.label),
                    value: element.value
                });
            });

            this.loading = false;
            
            return result;
        });
        this.choicesInitialSortOrder = structuredClone(this.choices);
        this.choicesInitialSortOrderBackup = structuredClone(this.choices);

        this.value = !this.value ? [] : _.castArray(this.value);

        this.applyTodaFilter();
    }

    _prepareColumns()
    {
        if (!this.config.columnLabels || this.config.columnLabels.length < 1) {
            throw new Error("You must specify at least one column label in checkboxesTable configuration.");
        }

        const columnOrder = [];
        const columnLabels = [];

        _.forEach(this.config.columnLabels, (label, property) => {
            columnOrder.push(property);
            columnLabels.push(label);
        });

        this.columnOrder = columnOrder;
        this.columnLabels = columnLabels;
    }

    _labelToArray(elementLabel)
    {
        const labels = [];

        this.columnOrder.forEach(column => {
            labels.push(elementLabel[column]);
        });

        return labels;
    }

    _translateLabel(elementLabel)
    {
        return this._labelToArray(elementLabel).map(label => this.i18n.tr(label));
    }

    allChecked(value)
    {
        if (this.choices == null || this.value == null) {
            return false;
        }

        return this.choices.length === this.value.length;
    }

    switchAll()
    {
        if (this.allChecked(this.value)) {
            this.value = [];
        } else {
            this.value = this.choices.map(
                choice => choice.value
            );
        }
    }

    onChange()
    {
        //Somewhat of a hack to trigger value change event and not reimplement the wheel here
        this.value = _.clone(this.value);
    }

    toggleSort(columnIndex, columnLabel)
    {
        if (this.sort[columnIndex] == null) {
            this.sortChoices("ASC", columnIndex, columnLabel);
            return;
        }
        if (this.sort[columnIndex] === "ASC") {
            this.sortChoices("DESC", columnIndex, columnLabel);
            return;
        }
        this.sort = {};
        this.choices = structuredClone(this.choicesInitialSortOrder);
    }

    sortChoices(sortMethod, labelIndex, columnLabel)
    {
        this.sort = {};
        this.sort[labelIndex] = sortMethod;
        this.choices = this.choices.sort((a, b) => {
            let labelA = a.label[labelIndex].toUpperCase();
            let labelB = b.label[labelIndex].toUpperCase();
            if(columnLabel === "sio.field.dueDate") {
                labelA = this.createDateObject(labelA);
                labelB = this.createDateObject(labelB);
            }
            if (labelA < labelB) {
                return sortMethod === "ASC" ? -1 : 1;
            }
            if (labelA > labelB) {
                return sortMethod === "ASC" ? 1 : -1;
            }
            return 0;
        });
    }

    createDateObject(string) {
        if(string === "") {
            string = "01.01.2100";
        }
        const newFormat = `${string.slice(3, 5)}/${string.slice(0, 2)}/${string.slice(6)}`;
        const date = new Date(newFormat);
        return date;
    }

    applyTodaFilter() {
        const today = new Date();
        const dateColumnIndex = this.columnLabels.indexOf("sio.field.dueDate");

        this.choicesInitialSortOrder = this.choicesInitialSortOrder.filter((choice) => {
            return this.createDateObject(choice.label[dateColumnIndex]) <= today;
        });

        this.sort = {};
        this.choices = structuredClone(this.choicesInitialSortOrder);
        this.filter.untilToday = true;

        // reset text search
        this.searchText = "";
        this.filter.text = false;
    }

    removeTodayFilter() {
        this.choicesInitialSortOrder = structuredClone(this.choicesInitialSortOrderBackup);
        this.sort = {};
        this.choices = structuredClone(this.choicesInitialSortOrder);
        this.filter.untilToday = false;

        // reset text search
        this.searchText = "";
        this.filter.text = false;
    }

    toggleTodayFilter() {
        if(this.filter.untilToday) {
            this.removeTodayFilter();
            return;
        }
        this.applyTodaFilter();
    }

    searchByText() {
        const inputColumnIndex = this.columnLabels.indexOf("fin-api.receiverName");

        const textSearchChoices = structuredClone(this.choicesInitialSortOrder).filter((choice) => {
            console.log(choice.label[inputColumnIndex]);
            return choice.label[inputColumnIndex].toUpperCase().includes(this.searchText.toUpperCase());
        });

        this.choices = textSearchChoices;
        this.filter.text = true;
    }

    type(event) {
        if(event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault();
            this.searchByText();
        }
        return true;
    }
}
