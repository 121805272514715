import {bindable, inject, LogManager, customElement} from 'aurelia-framework';
import * as _ from "lodash";
import {AirlineChoiceLoader} from "./airline-choice-loader";

const logger = LogManager.getLogger('AirlineChoiceElement');

@customElement('sio-airline-choice-input')
@inject(
    AirlineChoiceLoader
)
export class AirlineChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    loading = true;

    constructor(
        choiceLoader
    ) {
        this.choiceLoader = choiceLoader;
    }

    async attached()
    {
        this.loading = true;
        this._choices = await this.choiceLoader.load();
        this.loading = false;
    }
}
