import {bindable, computedFrom, customElement, inject} from 'aurelia-framework';
import {Client} from '../../api/client';
import {DialogService} from "aurelia-dialog";
import {RoomMatrixChoiceForm} from './room-matrix-choice-form';
import {BindingSignaler} from "aurelia-templating-resources";

@customElement('sio-room-matrix-choice-input')
@inject(
    Client,
    DialogService,
    BindingSignaler
)
export class RoomMatrixChoiceElement {
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    loading = true;

    showAdd = false;

    constructor(
        client,
        dialogService,
        signaler
    ) {
        this.client = client;
        this.dialogService = dialogService;
        this.signaler = signaler;
    }

    async attached() {
        this.loading = true;
        await this.fetchChoices();
        this.loading = false;

    }

    @computedFrom('config')
    get _widthCalc() {
        return 'width: calc(100% - 50px)';
    }


    async updateLabel(value) {

        let conditions = {
            id: value.id
        }
        let item = await this.fetchOccupancyType(conditions);

        if (item) {
            this.label = item.identifier;
        }

    }

    changeChoice(choice = null) {


        this.dialogService.open({
            viewModel: RoomMatrixChoiceForm,
            model: {
                value: Array.isArray(choice) ? null : choice,
                config: this.config,
            },
            lock: false
        }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
                this.updateValue(choice, response.output);
                await this.fetchChoices();
            }
        });
    }

    updateValue(choice, value) {
        if (this.config.multiple == true) {
            if (!Array.isArray(this.value)) {
                this.value = [];
            }
            this.value.push(value);
        } else {
            this.value = value;
        }
    }

    async fetchChoices() {

        if (!this.value) {
            return;
        }

        let values = this.value;

        if (values == null) {
            return;
        }

        if (this.config.multiple != true && !Array.isArray(values)) {
            values = [values];
        }

        values = values.map(value => {
            return value.id;
        })

        let conditions = {
            id: {
                $in: values
            }
        }

        let response = await this.client.get('tourism-room/occupancy-type?conditions=' + JSON.stringify(conditions));

        if (response.items.length > 0) {

            this._choices = response.items.map(item => {
                let keys = Object.keys(item.title);

                return {
                    value: {
                        id: item.id,
                        modelId: item.modelId
                    },
                    label: item.identifier + ' / ' + (item.title['de'] ?? item.title[keys[0]])
                }
            });
        }

    }

    async valueChanged(newValue, oldValue) {

        if (Array.isArray(newValue) && Array.isArray(oldValue)) {
            if (newValue.length < oldValue.length) {
                await this.fetchChoices();
            }
        }
    }
}
