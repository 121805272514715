import {bindable, inject, LogManager, customElement} from 'aurelia-framework';
import {ChoiceLoader} from "../loader/choice-loader.js";
import {I18N} from "aurelia-i18n";
import * as _ from "lodash";

import "./radio-table-choice-element.less";

const logger = LogManager.getLogger('RadioTableChoiceElement');

@customElement('sio-radio-table-choice-input')
@inject(
    ChoiceLoader,
    I18N
)
export class RadioTableChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    constructor(
        choiceLoader,
        i18n
    ) {
        this.choiceLoader = choiceLoader;
        this.i18n = i18n;
    }

    async configChanged(newValue, oldValue)
    {
        this.loading = true;

        if (this.config.translateChoiceLabel == null) {
            this.config.translateChoiceLabel = true;
        }

        this._prepareColumns();

        this.choices = await this.choiceLoader.getChoices(this.config).then(choices => {
            const result = [];

            choices.forEach((element) => {
                result.push({
                    label: this.config.translateChoiceLabel ?
                        this._translateLabel(element.label) : this._labelToArray(element.label),
                    value: element.value
                });
            });

            this.loading = false;

            return result;
        });

        this.value = !this.value ? [] : _.castArray(this.value);
    }

    _prepareColumns()
    {
        if (!this.config.columnLabels || this.config.columnLabels.length < 1) {
            throw new Error("You must specify at least one column label in radioTable configuration.");
        }

        const columnOrder = [];
        const columnLabels = [];

        _.forEach(this.config.columnLabels, (label, property) => {
            columnOrder.push(property);
            columnLabels.push(label);
        });

        this.columnOrder = columnOrder;
        this.columnLabels = columnLabels;
    }

    _labelToArray(elementLabel)
    {
        const labels = [];

        this.columnOrder.forEach(column => {
            labels.push(elementLabel[column]);
        });

        return labels;
    }

    _translateLabel(elementLabel)
    {
        return this._labelToArray(elementLabel).map(label => this.i18n.tr(label));
    }
}
