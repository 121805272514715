import {inject} from "aurelia-framework";
import {ChoiceLoader} from "../loader/choice-loader";

@inject(
    ChoiceLoader
)
export class AirlineChoiceLoader {
    constructor(
        choiceLoader
    ) {
        this.choiceLoader = choiceLoader;
    }

    load(requestConditions = null, initialValues = null)
    {
        return this.choiceLoader.getChoices(
            {modelId: 'aviation/airline'},
            requestConditions,
            initialValues,
            "limit=10000"
        );
    }
}
