import {bindable} from "aurelia-templating";
import * as _ from "lodash";

export class RoomMatrixChoiceField {

    @bindable config;
    @bindable({defaultBindingMode: 2}) value;
    @bindable({defaultBindingMode: 2}) values;

    @bindable callback;

    async attached() {
        this.loading = true;
        this.choiceConfig = _.cloneDeep(this.config);
        this.choiceConfig.choices = await this.mapChoices(await this.choiceConfig.choices);

        if (this.config.property in this.values) {
            this.value = this.values[this.config.property] ?? ''
        }

        if (!(this.choiceConfig.choices.filter(choice => choice.value == this.value
        ).length > 0)) {
            this.value = "";
        }
        this.loading = false;
    }

    mapChoices(choices) {
        let result = choices.map(choice => {
            return {
                value: choice.key,
                label: choice.key + ' - ' + (choice.value ? choice.value : '(Kein Titel)'),
                max: choice.max,
                min: choice.min,
            }
        });
        //result.unshift({value: "", label: ""})
        return result;
    }

    valueChanged(newValue) {
        this.values[this.config.property] = newValue;

        if (this.callback) {
            let choice = this.choiceConfig.choices.filter(choice => choice.value == newValue);

            if (choice.length > 0 && choice[0].max) {
                this.callback(choice[0].max, choice[0].min ?? 1);
            }
        }
    }
}
