import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {BindingSignaler} from "aurelia-templating-resources";
import {FlashService} from "../../flash/flash-service";

@inject(
    Client,
    DialogController,
    BindingSignaler,
    FlashService
)
export class RoomMatrixChoiceForm {

    values = {
        "min": 1,
        "max": 1,
        "minAdults": null,
        "maxAdults": null,
    };

    value;

    loading = true;

    choiceConfigs = [];

    constructor(client, controller, signaler, flash) {
        this.client = client;
        this.controller = controller;
        this.signaler = signaler;
        this.flash = flash;
    }

    async activate(value) {
        this.value = value.value;
        this.config = value.config;
        this.loading = true;

        await this.loadChoices();
        await this.loadInitialValue();
        this.signaler.signal('updated');

        this.loading = false;

    }

    async bind() {

    }

    async loadChoices() {
        const context = this.config?.options?.context;

        this.choiceConfigs = await this.client.get('tourism/room/matrix' + (context ? '/' + context : ''));

        this.choiceConfigs.map(choice => {
            this.values[choice.property] = null;
            return choice;
        })
    }

    buildTitle() {

        let title = [];

        for (const [key, value] of Object.entries(this.choiceConfigs)) {

            let matrixValue = this.values[value.property];

            if (!matrixValue) {
                continue;
            }

            let string = value.choices.filter(choice => choice.key == matrixValue).shift();

            if (!string) {
                continue;
            }

            title.push(string.value);

        }

        return title.join(" ");
    }

    async loadInitialValue() {

        if (!this.value || Array.isArray(this.value)) {
            return;
        }

        let conditions = {
            id: this.value.id
        }

        let response = await this.fetchOccupancyType(conditions);

        let initialValue;

        if (response.items.length > 0) {
            initialValue = response.items.shift();
        }

        if (!initialValue) {
            return;
        }

        this.splitIdentifiers(initialValue.identifier);
        this.values.min = initialValue.min ?? 1;
        this.values.max = initialValue.max ?? 1;
        this.values.minAdults = initialValue.minAdults ?? '';
        this.values.maxAdults = initialValue.maxAdults ?? '';

    }

    splitIdentifiers(identifier) {
        if (!identifier) {
            return;
        }

        let firstValue = identifier.split("-")[0];
        let index = 0;

        this.choiceConfigs.forEach((config, i) => {

            let choiceLength = config.choices?.[0]?.key?.length || 1;

            this.values[config.property] = firstValue.substring(index, index + choiceLength);

            index += choiceLength;
        })
    }

    async fetchOccupancyType(conditions) {
        return await this.client.get('tourism-room/occupancy-type?conditions=' + JSON.stringify(conditions));
    }

    buildIdentifier(values, crs) {
        let identifier = '';

        this.choiceConfigs.some(config => {
            let value = values[config.property];

            let string = config.choices.filter(choice => choice.key == value).shift();

            if (!string) {
                value = '~';
            }

            identifier = identifier + value;

        })

        //Remove fill characters in the end
        identifier = identifier.replace(/~+$/g, '');
        //Replace fill character with X, do not remove X if specified specifially (might be an option,e .g. for nicko)
        identifier = identifier.replace('~', 'X');

        if (identifier != "" && crs != true) {
            for (const [key, value] of Object.entries(this.values)) {
                if (['min', 'max', 'minAdults', 'maxAdults'].includes(key)) {
                    identifier = identifier + '-' + this.parseInteger(value);
                }
            }
        }

        return identifier;
    }

    buildConditions(values) {

        let conditions = {
            identifier: this.buildIdentifier(values),
            min: this.parseInteger(values.min),
            max: this.parseInteger(values.max)
        };


        conditions.minAdults = this.parseInteger(values.minAdults, true);
        conditions.maxAdults = this.parseInteger(values.maxAdults, true);

        return conditions;
    }

    parseInteger(int, _null = false) {
        int = parseInt(int);
        return (isNaN(int) ? (_null) ? null : 0 : int);
    }

    async ok() {

        if (this.buildIdentifier(this.values) == "") {
            return;
        }

        let conditions = this.buildConditions(this.values);

        let response = await this.fetchOccupancyType(conditions);

        let item = response.items.filter(item => {

                let valueMinAdults = this.values.minAdults ?? 0;
                let valueMaxAdults = this.values.maxAdults ?? 0;

                let itemMinAdults = item.minAdults ?? 0;
                let itemMaxAdults = item.maxAdults ?? 0;

                return item.min == this.values.min
                    && item.max == this.values.max
                    && itemMinAdults == valueMinAdults
                    && itemMaxAdults == valueMaxAdults
                    ;
            }
        ).shift();

        if (!item) {

            try {
                item = await this.createOccupancyType();
            } catch (err) {
                this.flash.error('Matrix schon mir anderen Teilnehmerzahlen vorhanden');
            }
        }

        if (!item) {
            return;
        }

        this.controller.ok({
            id: item.id,
            modelId: item.modelId
        });

    }

    async createOccupancyType() {

        let postRequest = {
            "identifier": "",
            "title": "",
            "min": 0,
            "max": 0,
            "minAdults": null,
            "maxAdults": null,
            "shared": null,
            "personBased": null,
            "assignByGender": null,
            "calculateAsSurcharge": null,
            "additionalSubLabel": null,
            "sharedSubLabel": null,
            "doNotListInCalculation": null,
            "crsCode": ""
        }

        for (const [key, value] of Object.entries(postRequest)) {
            if (key in this.values && this.values[key]) {
                postRequest[key] = this.parseInteger(this.values[key]);
            }
        }
        postRequest.title = this.buildTitle();
        postRequest.identifier = this.buildIdentifier(this.values);
        postRequest.crsCode = this.buildIdentifier(this.values, true);

        let response = await this.client.post('tourism-room/occupancy-type', postRequest);

        return response.data;

    }

    callback(max, min) {
        console.log('MAX', max, min);
        this.values.max = max;
        this.values.min = min;
    }

}


