import {bindable, customElement, inject, LogManager} from 'aurelia-framework';
import {ChoiceLoader} from "../loader/choice-loader.js";
import {I18N} from "aurelia-i18n";

const logger = LogManager.getLogger('RadiosChoiceElement');

@customElement('sio-radios-choice-input')
@inject(
    ChoiceLoader,
    I18N
)
export class RadiosChoiceElement {
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    constructor(
        choiceLoader,
        i18n
    ) {
        this.choiceLoader = choiceLoader;
        this.i18n = i18n;
    }

    async configChanged(newValue, oldValue) {
        this.loading = true;

        if (this.config.translateChoiceLabel == null) {
            this.config.translateChoiceLabel = true;
        }

        if (this.config.required == null) {
            this.config.required = true;
        }

        if (this.config.emptyLabel == null) {
            this.config.emptyLabel = '';
            this.i18n.tr('sio.form.radio-buttons.noChoice');
        }


        this.choices = await this.choiceLoader.getChoices(this.config).then(choices => {
            const result = [];

            choices.forEach(element => {
                result.push({
                    label: this.config.translateChoiceLabel ? this.i18n.tr(element.label) : element.label,
                    value: element.value,
                    help: element.help
                });
            });

            if (!this.config.required) {
                result.unshift({
                    label: this.config?.options?.emptyLabel ?? this.config.emptyLabel,
                    value: ""
                })
            }

            this.loading = false;

            return result;
        });
    }
}
