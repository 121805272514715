import {inject, bindable, computedFrom} from 'aurelia-framework';
import {Client} from "../../../api/client";
import {ThumbnailLoader} from "../thumbnail-loader/thumbnail-loader";
import * as _ from 'lodash';
import {ModuleConfigClient} from "../../../api/module-config-client";

import "./single-image-choice-element.less";

@inject(
    Client,
    ModuleConfigClient
)
export class SingleImageChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;
    @bindable callback = () => {};

    loading = {
        thumbnail: false
    };

    constructor(
        client,
        moduleConfigClient
    ) {
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
    }

    configChanged()
    {
        if (!this.config) {
            this.thumbnailLoader = null;

            return;
        }

         this.moduleConfigClient.getModel(this.config.modelId).then(
             model => {
                 this.thumbnailLoader = new ThumbnailLoader(this.client, model);
                 this._recalculateThumbnail();
             }
         );
    }

    valueChanged()
    {
        this._recalculateThumbnail();
    }

    _recalculateThumbnail()
    {
        if (!this.value || !this.thumbnailLoader) {
            return null;
        }

        this.loading.thumbnail = true;

        return new Promise(async (resolve, reject) => {
            const _thumbnail = {
                url: await this.thumbnailLoader.getThumbnailUrl(this.value, 320, 240),
                _source: this.value
            };

            this.loading.thumbnail = false;

            this.thumbnail = _thumbnail;
        });
    }
}
