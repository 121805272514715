import * as _ from 'lodash';

export class ThumbnailLoader
{
    constructor(
        client,
        itemModel
    ) {
        this.client = client;
        this.itemModel = itemModel;
    }

    async getTitle(item)
    {
        const imageTitle = await this._getTitleField(item);

        return imageTitle ? imageTitle : '';
    }

    async getThumbnailUrl(item, width = 190, height = 100)
    {
        let file = await this._getPreviewField(item);

        let url = '';

        if (file && file.previewUrl) {
            url = file.previewUrl.replace('$width', width).replace('$height', height);
        } else if (file && file.downloadUrl) {
            url = file.downloadUrl;
        }

        return url;
    }

    _getTitleField(item)
    {
        const titleField = _.get(this.itemModel, 'display.renderer.tile.titleField', null);

        if (!titleField) {
            return null;
        }

        if (!_.has(item._full, titleField)) {
            return titleField;
        }

        return _.get(item._full, titleField);
    }

    _getPreviewField(item)
    {
        const previewField = _.get(this.itemModel, 'display.renderer.tile.previewField', null);

        if (!previewField) {
            return null;
        }

        return this._loadFullItem(item).then(
            fullItem => {
                return _.get(fullItem, previewField, null);
            }
        );
    }

    _loadFullItem(item)
    {
        const previewField = _.get(this.itemModel, 'display.renderer.tile.previewField', '');

        return this.client.get(item.modelId + '/' + item.id + '?embeds[]=' + previewField, 5);
    }
}
