import AsyncSelect from "react-select/async";
import React, { useCallback, useState } from "react";
import { Container } from "aurelia-framework";
import { ChoiceLoader } from "./loader/choice-loader";
import debounce from "debounce-promise";
import "./react-choice-element.less";

export const ChoiceElement = (props) => {
    const { config, onChange, preselected } = props;
    const [defaultValue, setDefaultValue] = useState();
    const optionalProps = props.placeholder && {
        placeholder: props.placeholder,
    };
    const { multiple } = config;
    const choiceLoader = Container.instance.get(ChoiceLoader);

    const loadOptions = useCallback(
        debounce((inputValue) => {
            console.log("LOAD OPTIONS", inputValue);

            let conditions = {};

            if (inputValue) {
                conditions.search = inputValue;
            }

            return choiceLoader.getChoices(config, conditions);
        }, 500),
        [config, choiceLoader]
    );

    const loadPreselectedValue = useCallback(
        debounce(async (inputValue) => {
          console.log("LOAD OPTIONS", inputValue);
      
          let conditions = {};
      
          if (inputValue) {
            conditions.search = inputValue;
          }
      
          try {
            const choices = await choiceLoader.getChoices(config, conditions);
            const foundChoice = choices.find((option) => option.value.id === preselected?.id);
      
            if (foundChoice) {
                setDefaultValue(foundChoice);
            } else {
              console.log("Choice not found");
            }
          } catch (error) {
            console.error("Error loading choices:", error);
          }
        }, 500),
        [config, choiceLoader]
      );

    loadPreselectedValue()

    const convertValue = useCallback(
        (value) => {
            if (value?.[0]?.value) {
                onChange(value?.map((e) => {
                    return e.value;
                }));
            } else {
                onChange(value?.value);
            }
        },
        [onChange]
    );

    return (
        <>
        { !preselected || defaultValue ? (<AsyncSelect
            className="react-choice-element"
            classNamePrefix="react-choice-element"
            cacheOptions
            defaultOptions
            isClearable
            {...optionalProps}
            styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            defaultValue={defaultValue}
            menuPortalTarget={document.body}
            isMulti={multiple === true}
            loadOptions={loadOptions}
            onChange={convertValue}
            />) : null}
        </>
    );
};
